import React from 'react'
import Layouts from './pages/Layouts'
import Home from './pages/Home'
import Contact from './pages/Contact'
import Cart from './pages/Cart'
import Products from './pages/Products'
import SingleProduct from './pages/SingleProduct'
import ErrorPage from './pages/ErrorPage'
import About from './pages/About'
import Login from './pages/Login'
import PrivateRoute from './pages/PrivateRoute'
import { Routes, Route } from 'react-router-dom'
import { useState } from 'react'
import { ThemeProvider } from 'styled-components'

import { GlobalStyle } from './GlobalStyle'
import SignUp from './pages/SignUp'


function App() {
  const [islogged, setIsLogged] = useState(false)
  const theme = {
    colors: {
      heading: "rgb(24 24 29)",
      text: "rgba(29 ,29, 29, .8)",
      white: "#fff",
      black: " #212529",
      helper: "#8490ff",

      bg: "#F6F8FA",
      footer_bg: "#0a1435",
      btn: "rgb(98 84 243)",
      border: "rgba(98, 84, 243, 0.5)",
      hr: "#ffffff",
      gradient:
        "linear-gradient(0deg, rgb(132 144 255) 0%, rgb(98 189 252) 100%)",
      shadow:
        "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;",
      shadowSupport: " rgba(0, 0, 0, 0.16) 0px 1px 4px",
    },
    media: {
      mobile: "768px",
      tab: "998px",
    },
  };

  return (
    <div>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Routes>

          <Route element={
            <PrivateRoute islogged={islogged}>
              <Layouts islogged={islogged} setIsLogged={setIsLogged} />
            </PrivateRoute>} >

            <Route index path='/' element={<Home />} />
            <Route path='contact' element={<Contact />} />
            <Route path='cart' element={<Cart />} />
            <Route path='products' element={<Products />} />
            <Route path='/about' element={<About />} />
            <Route path='singleProduct/:id' element={<SingleProduct />} />
            <Route path='*' element={<ErrorPage />} />

          </Route>
          <Route path='login' element={<Login setIsLogged={setIsLogged} />} />
          <Route path='signup' element={<SignUp setIsLogged={setIsLogged}/>} /> 
          
        </Routes>
      </ThemeProvider>
    </div>
  )
}

export default App