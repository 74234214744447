import { useAuth0 } from "@auth0/auth0-react";
import { Wrapper } from "../styles/ContactStyle";

const Contact = () => {

  const {isAuthenticated,user}=useAuth0()


  return (
    <Wrapper>
      <h3 className="common-heading">Contact Page</h3>

      <iframe title="ywii" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13721.252134909682!2d76.67927873278795!3d30.70959904226564!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390feeffdb1fc9f3%3A0x6f26214a4e09a8a7!2sSector%2074%2C%20Sahibzada%20Ajit%20Singh%20Nagar%2C%20Punjab%20140308!5e0!3m2!1sen!2sin!4v1696486830826!5m2!1sen!2sin" width="100%" height="450" style={{border:'0'}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>

      <div className="container">
        <div className="contact-form">
          <form className="contact-inputs" method="POST" action="https://formspree.io/f/xgejprwe">

            <input type="text" value={isAuthenticated?user.name:''} name="username" placeholder="username" autoComplete="off" required />
            <input type="email" name="Email" value={isAuthenticated?user.email:''} placeholder="Email" autoComplete="off" required />
            <textarea name="messages" cols="30" rows="10" placeholder="type your message" required autoComplete="off" />
            <input type="submit" value="send" />
          </form>
        </div>
      </div>
  
    </Wrapper>
  )
};

export default Contact;
