import React from 'react'
import { Wrapper } from '../styles/StarsStyle'
import { FaStarHalfAlt, FaStar } from 'react-icons/fa'
import { AiOutlineStar } from 'react-icons/ai'

function Stars({ stars, reviews }) {

  const ratingStar = Array.from({ length: 5 }, (currElm, index) => {
    let number = index + 0.5

    return (
      <span key={index}>
        {stars >= index + 1 ? (
          <FaStar className='icon' />
        ) : (stars >= number ?
          (
            <FaStarHalfAlt className='icon' />
          )
          : (
            <AiOutlineStar className='icon' />
          )
        )}
      </span>
    )

  })


  return (
    <Wrapper>
      <div className="icon-style">
        {ratingStar}
        <p>{reviews} customer reviews</p>
      </div>
    </Wrapper>
  )


}

export default Stars