import React, { useState } from 'react'
import styled from 'styled-components'
import { Button } from '../styles/Button';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import { NavLink } from 'react-router-dom';


function Login({ setIsLogged, }) {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ email: "", password: '' })
  const [showpassword, setShowPassword] = useState(false);

  const changeHandler = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }))
  }

  function sumbitHandler(e) {
    e.preventDefault();
    setIsLogged(true)
    toast.success('logedin')
    navigate('/')
    localStorage.setItem('formdata', JSON.stringify(formData))
  }
  return (
    <Wrapper className='containers'>
      <div className="grid grid-two-column">

        <form className='login-box' onSubmit={sumbitHandler}>
          <h2>Login to Your Account</h2>
          <p>Login using social networks</p>
          <fieldset className='one'> <legend>&nbsp;OR&nbsp;</legend> </fieldset>
          <input type="email" name='email'
            placeholder='email'
            required
            value={formData.email}
            onChange={changeHandler} />
          <label>
            <input
              type={showpassword ? "text" : "password"} name='password'
              placeholder='password'
              required
              value={formData.password}
              onChange={changeHandler} />
            <span onClick={() => setShowPassword((prev) => !prev)}>
              {showpassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
            </span>
          </label>
          <Button type='submit'>sign in</Button>
        </form>
        <div className='signup-box'>
          <h2> New Here?</h2>
          <p className='box-p' >Sign up and discover a great <br /> amount of new opportunities!</p>
           
          <NavLink to='/signup' >
          <Button>sign up</Button>
          </NavLink>

        </div>
      </div>
    </Wrapper>
  )
}
const Wrapper = styled.div`
padding: 5rem 0;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  width:100vw;
  height:100vh

.grid-two-column {
    grid-template-columns:55% 45%;
  
  }
   
  .one{
    text-align:center;
    border-bottom:none;
    border-left:none;
    border-right:none;
    width:60%;

  }
  .login-box{
      display:flex;
      flex-direction:column;
    justify-content:center;
    align-items:center;
    padding:50px;
    
  }
  .signup-box{
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    padding:50px;
    background-color: ${({ theme }) => theme.colors.bg};
   margin-right:100px;
  }
  h2{
    font-weight:bold;
    font-size:40px;
  }
  p{
    margin:10px
  }
   input{
    width:30rem;
    border-radius:10px;
    margin:10px;
    padding:15px;
   }
   input:focus{
    outline:none
   }
   Button{
    margin-top:20px;
   }
   .box-p{
    margin-top:20px;
   }
   label{
    position:relative;
   }
   span{
    position:absolute;
    margin-top:25px;
    margin-left:-35px;
    font-size:15px;
   }
`;
export default Login