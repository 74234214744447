const filterReducer = (state, action) => {
    switch (action.type) {

        case "SET_LOADING":
            return {
                ...state,
                isLoading: true
            }

        case "SET_GRID_VIEW":
            return {
                ...state,
                grid_view: true
            }

        case "SET_LIST_VIEW":
            return {
                ...state,
                grid_view: false
            }

        case "LOAD_FILTER_PRODUCT":
            return {
                filter_products: [...action.payload],
                all_products: [...action.payload],
            }

        case "SET_SORTING":
            return {
                ...state,
                sorting_value: action.payload
            }

        case "SORTING_PRODUCT":
            let newsorting;
            const { filter_products } = state;
            let defaultsort = [...filter_products]

            const sortProduct = (a, b) => {
                // if (state.sorting_value === "lowest") {
                //     return a.price - b.price
                // }
                // if (state.sorting_value === "highest") {
                //     return b.price - a.price
                // }
                // if (state.sorting_value === "a-z") {
                //     return a.name.localeCompare(b.name)
                // }
                // if (state.sorting_value === "z-a") {
                //     return b.name.localeCompare(a.name)
                // }
                switch (state.sorting_value) {
                    case "lowest":
                        return a.price - b.price
                    case "highest":
                        return b.price - a.price
                    case "a-z":
                        return a.name.localeCompare(b.name)
                    case "z-a":
                        return b.name.localeCompare(a.name)

                    default:
                        return state
                }
            }
            newsorting = defaultsort.sort(sortProduct)
            return {
                ...state,
                filter_products: newsorting,
                isLoading: false
            }

            case "UPDATE_FILTER":
                const { name, value } = action.payload;
          
                return {
                  ...state,
                  filters: {
                    ...state.filters,
                    [name]: value,
                  },
                };

                

                    
        default:
            return state
    }

}

export default filterReducer