import React from 'react'
import { NavLink } from 'react-router-dom';
import { Button } from '../styles/Button'
import hero from '../images/hero.jpg'
import { Wrapper } from '../styles/HeroStyle';

function HeroSection({ myData }) {
    const { name } = myData
    return (
        <Wrapper>
            <div className='container'>
                <div className='grid grid-two-column'>
                    <div className='hero-section-data'>
                        <p className='intro-data'>Wellcome To</p>
                        <h1>{name}</h1>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestias
                            atque temporibus veniam doloribus libero ad error omnis voluptates
                            animi! Suscipit sapiente.</p>
                        <NavLink>
                            <Button>show now</Button>
                        </NavLink>
                    </div>

                    <div className='hero-section-image'>
                    <figure>
                    <img src={hero} className='img-style' alt='fghjk' />
                    </figure>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}

export default HeroSection