import React, { useState } from 'react'
import { Wrapper } from '../styles/AddToCartStyle'
import { FaCheck } from 'react-icons/fa'
import { NavLink } from 'react-router-dom'
import { Button } from '../styles/Button'
import CartAmmoutToggle from './CartAmmoutToggle'
import { useCartContext } from '../context/cartContext'
import toast from 'react-hot-toast'


function AddToCart({ product }) {
  // console.log(product)

  const { addToCart } = useCartContext()

  const { id, colors, stock } = product
  // console.log(colors)
  const [color, setColor] = useState(colors[0])
  const [amount, setAmount] = useState(1)

  const setIncrease = () => {
    amount < stock ? setAmount(amount + 1) : setAmount(stock)
  }

  const setDecrease = () => {
    amount > 1 ? setAmount(amount - 1) : setAmount(1)
  }
  const handleClick = () => {
    addToCart({ id, color, amount, product })
    toast.success("Item Added")

  }

  return (
    <Wrapper>
      <div className="colors">
        <p>
          Colors:
          {colors.map((currColor, i) => {
            return (
              <button key={i}
                style={{ backgroundColor: currColor }}
                className={color === currColor ? "btnStyle active" : "btnStyle"}
                onClick={() => setColor(currColor)}
              >
                {color === currColor ? <FaCheck className="checkStyle" /> : null}
              </button>
            )
          })}
        </p>
      </div>
      <CartAmmoutToggle amount={amount} setDecrease={setDecrease} setIncrease={setIncrease} />
      <NavLink >
        <Button onClick={() => handleClick({ id, color, amount, product })} className="btn">Add to cart</Button>
      </NavLink>
    </Wrapper>
  )
}

export default AddToCart