import { useContext, useEffect } from "react";
import { createContext, useReducer } from "react";
import reducer from '../reducer/cartReducer'
import toast from "react-hot-toast"


const cartContext = createContext()

const localdata = () => {
    let localCart = localStorage.getItem('cartItem');
    if (!localCart) {
        return [];
    }
    else {
        return JSON.parse(localCart)
    }
}

const initialState = {
    cart:localdata(),
    total_item:'',
    total_price:'',
    shipping_fee:5000,
    
}

const CartProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    const addToCart = ({ id, color, amount, product }) => {
        dispatch({ type: "ADD_TO_CART", payload: { id, color, amount, product } })
    }

    const removeItem = (id) => {
        dispatch({ type: "REMOVE_ITEM", payload: id })
        toast.error(" you removed an item!", { style: { padding: '16px' } })

    }

    const clearCart = () => {
        dispatch({ type: "CLEAR_CART" })
        toast.error("Cart is empty continue shoping!", { style: { padding: '16px' } })

    }
    const setIncrease = (id) => {
        dispatch({ type: "SET_INCREASE", payload: id })
    }
    const setDecrease = (id) => {
        dispatch({ type: "SET_DECREASE", payload: id })
    }



    useEffect(() => {
        dispatch({type:"CART_VALUE"})
        dispatch({type:"TOTAL_PRICE"})
        localStorage.setItem('cartItem', JSON.stringify(state.cart))
    }, [state.cart])

    return <cartContext.Provider value={{ ...state, addToCart, removeItem, clearCart, setDecrease, setIncrease }}>
        {children}
    </cartContext.Provider>

}

const useCartContext = () => {
    return useContext(cartContext)
}

export { cartContext, CartProvider, useCartContext }