import React from 'react'
import { Wrapper } from '../styles/ErrorPageStyle'
import {NavLink} from 'react-router-dom'
import {Button} from '../styles/Button'
function ErrorPage() {
  return (
    <Wrapper>
    <div className="container">
    <div>
    <h2>404</h2>
    <h3>UH OH! You're lost.</h3>
    <p>
      The page you are looking for does not exist. How you got here is a
      mystery. But you can click the button below to go back to the
      homepage.
    </p>

    <NavLink to="/" >
    <Button>go to home</Button>
    </NavLink>

    </div>
    </div>
    </Wrapper>
  )
}

export default ErrorPage