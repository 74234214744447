import { createContext, useContext, useEffect, useReducer } from "react";
import { useProductContext } from "./Productcontext";
import reducer from '../reducer/filterReducer'



const FilterContext = createContext()

const initialState = {
  isLoading: false,
  grid_view: true,
  filter_products: [],
  all_products: [],
  sorting_value: "lowest",
  filters:{
    category:"all"
  }
}

export const FilterContextProvider = ({ children }) => {
  const { products } = useProductContext()
  const [state, dispatch] = useReducer(reducer, initialState)

  const setGridView = () => {
    return dispatch({ type: "SET_GRID_VIEW" })

  }
  const setListView = () => {
    return dispatch({ type: "SET_LIST_VIEW" })

  }

  const sorting = (event) => {
    let uservalue = event.target.value
    return dispatch({ type: "SET_SORTING", payload: uservalue })
  }

  const updateFilter=(e)=>{
let name=e.target.name;
let value=e.target.value;

return dispatch({type:"UPDATE_FILTER",payload:{name,value}})

  }
  


  useEffect(() => {
    dispatch({type:"FILER_PRODUCTS"}) 
    dispatch({ type: "SORTING_PRODUCT" })
  }, [products, state.sorting_value,state.filters ])

  useEffect(() => {
    dispatch({ type: "LOAD_FILTER_PRODUCT", payload: products })
  }, [products])

  return (
    <FilterContext.Provider 
      value={{ ...state, setGridView,updateFilter, sorting, setListView,   }}>
    {children}
  </FilterContext.Provider>)
}

export const useFilterContext = () => {
  return useContext(FilterContext)
}
