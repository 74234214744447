import React from 'react'
import { Navigate } from 'react-router-dom'


function PrivateRoute({islogged,children}) {

    if(islogged){
        return children
    }
    else{
        return <Navigate to={'/login'} />
    }
   
}

export default PrivateRoute