import React from 'react'
import HeroSection from '../componets/HeroSection'
import Services from '../componets/Services'
import Trusted from '../componets/Trusted'
import FeatureProducts from '../componets/FeatureProducts'

function Home() {
  const data={
    name:"Lakhi Store",
  }
  return (
    <div>
    <HeroSection myData={data} />
    <FeatureProducts/>
    <Services/>
    <Trusted/>
    </div>
  )
}
 

export default Home