

function ProductReducer(state, action) {

    //     if(action.type==="SET_LOADING"){
    //         return{
    //             ...state,
    //             isLoading:true
    //         }
    //     }
    //     if(action.type==="API_ERROR"){
    //         return{
    //             ...state,
    //             isLoading:false,
    //             isError:true,
    //         }
    //     }
    //     if(featureData=="SET_API_DATA"){
    //         const featureData=action.payload.filter((curElm)=>{
    //             return curElm.featureData 
    //         })
    //         return {
    //             ...state,
    //             isLoading:false,
    //             products:action.payload,
    //             featureProducts:featureData,
    //         }
    // return state
    //     }


    switch (action.type) {
        case "SET_LOADING":
            return {
                ...state,
                isLoading: true
            }
        case "SET_API_DATA":
            const featureData = action.payload.filter((curElm) => {
                return curElm.featured === true;
            })
            return {
                ...state,
                isLoading: false,
                products: action.payload,
                featureProducts: featureData,
            }
        case "API_ERROR":
            return {
                ...state,
                isLoading: false,
                isError: true
            }


        case "SET_SINGLE_LOADING":
            return {
                ...state,
                isSingleLoading: true
            }

        case "SET_SINGLE_ERROR":
            return {
                ...state,
                isSingleLoading: false,
                isError: true
            }

        case "SET_SINGLE_PRODUCT":
            return {
                ...state,
                isSingleLoading: false,
                singleProduct: action.payload
            }

            
        default:
            return state

    }

}


export default ProductReducer