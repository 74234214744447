import React from 'react'
import { useFilterContext } from '../context/FilterContext'
import GridView from './GridView'
import ListView from './ListView'

 const ProductList=({result})=>{
    const { grid_view=true}=useFilterContext();
    if(grid_view){
        return <GridView products={result} />
    }
    if(grid_view===false){
        return <ListView products={result} />
    }
 }
export default ProductList