import HeroSection from '../componets/HeroSection'
import { useProductContext } from '../context/Productcontext'

function About() {

const {myName}=useProductContext()

  const data={
    name:"Lakhi Ecommerce"
  }
  return (
    <div>
    {myName}
    <HeroSection myData={data}/>
    </div>
  )
}

export default About