import React from 'react'
import { Wrapper } from '../styles/FilterSectionStyle'


function FilterSection({ handleChange , query,  }) {
  return (
    <Wrapper>
      <div className="filter-search">
        <form>
          <input type="text" name='text' value={query} placeholder='Search' onChange={handleChange} />
        </form>
      </div>

    </Wrapper>
  )
}

export default FilterSection