import React from 'react'
import { Wrapper } from '../styles/FooterStyle'
import { NavLink } from 'react-router-dom'
import { Button } from '../styles/Button'

import { FaYoutube, FaDiscord, FaInstagram } from 'react-icons/fa'

function Footer() {
    return (
        <Wrapper>
            <section className='contact-short'>
                <div className="grid grid-two-column">
                    <div>
                        <h3>Ready to get started?</h3>
                        <h3>Talk to us today</h3>
                    </div>
                    <div>
                        <Button className="btn hireme-btn">
                            <NavLink to="/"> Get Started </NavLink>
                        </Button>
                    </div>
                </div>
            </section>

            <footer>
                <div className="container grid grid-four-column">
                    <div className="footer-about">
                        <h3>Lahki ecommerce</h3>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit, voluptatum!</p>
                    </div>
                    <div className="footer-subscribe">
                        <h3>subscribe to get important updates</h3>
                        <form action="#">
                            <input type="email" name='email' placeholder='YOUR E-MAIL' />
                            <input type="submit" value='subscribe' />
                        </form>
                    </div>

                    <div className="footer-social">
                        <h3>Follow Us</h3>
                        <div className="footer-social--icons">
                            <div>
                                <FaDiscord className="icons" />
                            </div>
                            <div>
                                <FaInstagram className="icons" />
                            </div>
                            <div>
                                <FaYoutube className="icons" />
                            </div>
                        </div>
                    </div>

                    <div className="footer-contact">
                        <h3>Call Us</h3>
                        <p>+91 1234567890</p>
                    </div>
                </div>
                <div className="footer-bottom--section">
                    <hr />
                    <div className="container grid grid-two-column">
                        <div>
                            <p> @{new Date().getFullYear()}LAKHI E-COMMERCE. All Rights Reserved</p>
                        </div>

                        <div>
                            <p>PRIVACY POLICIY</p>
                            <p>TERMS & CONDITIONS</p>
                        </div>
                    </div>
                </div>
            </footer>

        </Wrapper>
    )
}

export default Footer