import React, { useState } from "react";
import styled from "styled-components";
import { Button } from "../styles/Button";
import { useNavigate,NavLink } from "react-router-dom";
import {AiOutlineEye,AiOutlineEyeInvisible} from 'react-icons/ai'

function SignUp({setIsLogged}) {
  const navigate=useNavigate()
  const [showpassword,setShowPassword]=useState(false)
  const [showpasswords,setShowPasswords]=useState(false)
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    confirmpassword: "",
  });

  function changeHandle(e){
    setFormData((prev)=>({
      ...prev,
      [e.target.name]:e.target.value
    }))
  }

  function submitHandler(e){
    e.preventDefault()
    if(formData.password!==formData.confirmpassword){
      return;
    }
     setIsLogged(true)
     navigate('/')
     localStorage.setItem('formdata',JSON.stringify(formData))
  }

  return (
    <Wrapper>
      <div className="grid grid-one-column">
        <form className="signup-box" onSubmit={submitHandler} >
          <h2>SIGN UP</h2>
          <div className="grid-two-column">
          <input
          className="inputs"
          type="text"
          value={formData.firstname}
          name="firstname"
          placeholder="firstname"
          onChange={changeHandle}
          />
          <input
          className="inputs"
          type="text"
          value={formData.lastname}
          name="lastname"
          placeholder="lastname"
          onChange={changeHandle}
          />
          </div>
          <input
          className="input"
            type="email"
            value={formData.email}
            name="email"
            placeholder="email"
            onChange={changeHandle}
            required
          />
          <label>
          <input
          className="input"
          type={showpassword?"text":"password"}
          value={formData.password}
          name="password"
          placeholder="password"
          onChange={changeHandle}
          required
          />
          <span className="eye" onClick={()=>setShowPassword((prev)=>!prev)}>
          {showpassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
          </span>
          </label>
          <label>
          <input
          className="input"
          type={showpasswords?"text":"password"}
          oninvalid="alert('You must fill out the form!');"
          value={formData.confirmpassword}
          name="confirmpassword"
          placeholder="confirmpassword"
          onChange={changeHandle}
          required
          />
          <span className="eye" onClick={()=>setShowPasswords((prev)=>!prev)}>
          {showpasswords ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
          </span>
          </label>
          <p>Already have account? <NavLink to={'/login'}><span className="login-a" >Login</span></NavLink></p>
          <Button>Sign up</Button>
        </form>
      </div>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  padding: 5rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;

  .grid-one-column {
    grid-template-columns: auto;
  }
   .login-a{
    font-weight:600;
    color:blue;
   }
  .signup-box {
    width: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 50px;
    background-color: ${({ theme }) => theme.colors.bg};
  }
.inputs{
  border-radius:10px;
  margin:10px;
  padding:15px;
}
  .input {
    width: 40.8rem;
    border-radius: 10px;
    margin: 10px;
    padding: 15px;
  }
  input:focus {
    outline: none;
  }
  h2{
    margin-bottom:30px;
    font-weight:bold;
  }
  p{
    margin:10px;
  }
  label{
    position:relative;
   }
   .eye{
    position:absolute;
    margin-top:25px;
    margin-left:-35px;
    font-size:15px;
   }
`;

export default SignUp;
