import { Wrapper } from '../styles/SingleProductStyle';
import { NavLink, useParams } from 'react-router-dom'
import React from 'react'
import { useProductContext } from "../context/Productcontext";
import { useEffect } from "react";
import PageNavigation from '../componets/PageNavigation';
import { Container } from '../styles/ContainerStyle';
import FormatPrice from '../Helper/FormatPrice'
import MyImage from '../componets/MyImage';
import { TbTruckDelivery, TbReplace } from 'react-icons/tb'
import { MdSecurity } from 'react-icons/md'
import Stars from '../componets/Stars';
import AddToCart from '../componets/AddToCart';
import { Button } from '../styles/Button';




function SingleProduct() {
  const API = "https://api.pujakaitem.com/api/products"
  const { getSingleProduct, isSingleLoading, singleProduct } = useProductContext();
  
  const { id } = useParams();
  
  const {
    name,
    company,
    price,
    description,
    stock,
    stars,
    reviews,
    image, } = singleProduct;

  useEffect(() => {
    getSingleProduct(`${API}?id=${id}`)
  })

  if (isSingleLoading) {
    return <div className='page_loading'>......Loading</div>
  }

  return <Wrapper>
    <PageNavigation title={name} />
   
    <Container className="container">
   
      <div className="grid grid-two-column">

        <div className="product_images">
          <MyImage image={image} />
        </div>

        <div className="product-data">
          <h2>{name}</h2>
          <Stars stars={stars} reviews={reviews} />
          <p className="product-data-price">
            MRP:
            <del>
              <FormatPrice price={price + 250000} />
            </del>
          </p>
          <p className="product-data-price product-data-real-price">
            Deal of the Day: <FormatPrice price={price} />
          </p>
          <p>{description}</p>
          <div className="product-data-warranty">
            <div className="product-warranty-data">
              <TbTruckDelivery className="warranty-icon" />
              <p>Free Delivery</p>
            </div>

            <div className="product-warranty-data">
              <TbReplace className="warranty-icon" />
              <p>30 Days Replacement</p>
            </div>

            <div className="product-warranty-data">
              <TbTruckDelivery className="warranty-icon" />
              <p>Lakhi Delivered</p>
            </div>

            <div className="product-warranty-data">
              <MdSecurity className="warranty-icon" />
              <p>2 Year Warranty</p>
            </div>
          </div>

          <div className="product-data-info">
            <p>
              Availabel:
              <span>{stock > 0 ? "In stock" : "not Availabel"}</span>
            </p>
            <p>
              ID: <span>123</span>
            </p>
            <p>
              Brand: <span>{company}</span>
            </p>
          </div>

          <hr />
          {stock > 0 && <AddToCart product={singleProduct} />}
          <NavLink to="/products">
          <Button >Go Back</Button>
      
          </NavLink>

        </div>

      </div>
    </Container>

  </Wrapper>
}



export default SingleProduct;
