import React, { useState } from 'react'
import { Wrapper } from '../styles/MyImageStyle'
import ImageZoom from 'js-image-zoom'
 


function MyImage({image=[{url:''}]}) {

  const [mainIamge,setMainImage]=useState(image[0])
  
  const options={
    zoomWidth:100,
    zoomPosition: 'right',
    scale:2.5,
    offset: {
      vertical: 0,
      horizontal: 10
    },
    zoomLensStyle: {
      Width:'350px',
    }

  }
  new ImageZoom(document.getElementById("img-container"),options)
  return (
    <Wrapper>
    <div className="grid grid-four-column">
    {
      image.map((data,i)=>{
        return (
          <figure key={i}>
          <div>
          <img src={data.url} alt={data.filename}  className='box-image--style'  onClick={()=>setMainImage(data)}/>
          </div>
          </figure>
        )
      })
    }
    </div>

    <div className="main-screen" id="img-container">
    <img src={mainIamge.url} alt={mainIamge.filename} />
    </div>
    </Wrapper>
  )
}

export default MyImage