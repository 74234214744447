import React from 'react'
import FormatPrice from '../Helper/FormatPrice'
import CartAmmoutToggle from './CartAmmoutToggle'
import { FaTrash } from 'react-icons/fa'
import { useCartContext } from '../context/cartContext'
import toast from 'react-hot-toast'



function CartItem({ id, image, price, name, color, amount, max }) {
    console.log(amount)

    const { removeItem, setDecrease, setIncrease } = useCartContext()

    const DecreaseHandler = (id) => {
        if (amount > 1) {
            setDecrease(id)
            toast.error("ITEM REMOVED", { style: { padding: '16px' } })
        }
    }
    const IncreaseHandler = (id) => {
        if (amount < max) {
            setIncrease(id)
            toast.success("ITEM ADDED", { style: { padding: '16px' } })

        }
    }
    return (
        <div className='cart-heading grid grid-five-column'>
            <div className="cart-image--name">
                <div>
                    <figure>
                        <img src={image} alt={id} />
                    </figure>
                </div>
                <div>
                    {name}
                    <div className="color-div">
                        <p>color:</p>
                        <div className="color-style" style={{ backgroundColor: color, color: color }}>

                        </div>
                    </div>
                </div>
            </div>

            <div className="cart-hide">
                <p><FormatPrice price={price} /></p>
            </div>

            <CartAmmoutToggle amount={amount}
                setDecrease={() => DecreaseHandler(id)} setIncrease={() => IncreaseHandler(id)} />

            <div className="cart-hide">
                <p>
                    <FormatPrice price={price * amount} />
                </p>
            </div>
            <div>
                <FaTrash className="remove_icon" onClick={() => removeItem(id)} />
            </div>

        </div>
    )
}

export default CartItem