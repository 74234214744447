import React from 'react'
import { Outlet } from 'react-router-dom'
import Header from '../componets/Header'
import Footer from '../componets/Footer'

function Layouts({setIsLogged,islogged}) {
   
    return (
       <div>
       <Header islogged={islogged} setIsLogged={setIsLogged} />
       <Outlet/>
       <Footer />
       </div>
       )
}

export default Layouts