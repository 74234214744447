import React from 'react'
import { Wrapper } from '../styles/TrustedStyle'
function Trusted() {
  return (
    <Wrapper className='brand-section'>
    <div className='container'>
    <h3>Trusted by 1000+ companies</h3>
    <div className='brand-section-slider'>
    <div className="slide">
    <img
      src="https://raw.githubusercontent.com/solodev/infinite-logo-carousel/master/images/image2.png"
      alt="trusted-brands"
    />
  </div>
  <div className="slide">
    <img
      src="https://raw.githubusercontent.com/solodev/infinite-logo-carousel/master/images/image3.png"
      alt="trusted-brands"
    />
  </div>
  <div className="slide">
    <img
      src="https://raw.githubusercontent.com/solodev/infinite-logo-carousel/master/images/image4.png"
      alt="trusted-brands"
    />
  </div>
  <div className="slide">
    <img
      src="https://raw.githubusercontent.com/solodev/infinite-logo-carousel/master/images/image6.png"
      alt="trusted-brands"
    />
  </div>
  <div className="slide">
    <img
      src="https://raw.githubusercontent.com/solodev/infinite-logo-carousel/master/images/image8.png"
      alt="trusted-brands"
    />
  </div>
    </div>
    </div>

    </Wrapper>
  )
}

export default Trusted