import React from 'react'
import { NavLink } from 'react-router-dom';
import styled from 'styled-components'
import logo from '../images/lakhi.png'
import Nav from './Nav';

function Header({islogged,setIsLogged}) {
    return (
        <MainHeader>
            <NavLink to={'/'}>
                <img src={logo} alt='ee' height={50} />
            </NavLink>
            <Nav islogged={islogged} setIsLogged={setIsLogged}/>
        </MainHeader>
    )
}
const MainHeader = styled.header`
padding: 0 4.8rem;
height: 10rem;
background-color: ${({ theme }) => theme.colors.bg};
display: flex;
justify-content: space-between;
align-items: center;
position: relative;

.logo {
  height: 5rem;
}
`;

export default Header