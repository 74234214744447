import React from 'react'
import { Wrapper } from '../styles/SortStyle'
import { BsFillGridFill, BsList } from 'react-icons/bs'
import { useFilterContext } from '../context/FilterContext'

function Sort({result}) {
  const {  grid_view=true, setGridView, setListView, sorting,isLoading } = useFilterContext()
 
  if(isLoading===true){
    return <div>................loading</div>
  }

  return (
    <Wrapper className="sort-section" >
      <div className="sorting-list--grid">
        <button className={grid_view ? " active sort-btn" : "sort-btn"} onClick={setGridView} >
          <BsFillGridFill className="icon" />
        </button>
        <button className={grid_view ? " sort-btn" : "active sort-btn"} onClick={setListView}>
          <BsList className="icon" />
        </button>
      </div>
      <div className="product-data">
        <p>{`${result.length}`} Product Available </p>
      </div>
      <div className="sort-selection">
          <form action="#">
          <label htmlFor="sort"></label>
          <select name="sort" id="sort" className='sort-selection--style' onClick={sorting}>
          <option value="lowest"> Price (lowest)</option>
          <option value="highest"> Price (Highest) </option>
          <option value="a-z">A-Z (a-z)</option>
          <option value="z-a">Z-A (z-a)</option>
          </select>
          </form>

      </div>

    </Wrapper>
  )
}

export default Sort