import React from "react";
import { useState } from "react";
import styled from "styled-components";
import { useFilterContext } from "../context/FilterContext";
import FilterSection from "../componets/FilterSection";
import Sort from "../componets/Sort";
import ProductList from "../componets/ProductList";

const Products = () => {
  const { filter_products } = useFilterContext()
  const [query, setQuery] = useState('')

  const handleChange = (e) => {
    setQuery(e.target.value)
  }
   
  const  result = filter_products.filter((curEl) => {
    return curEl.name.toLowerCase().includes(query)
  })


  return <Wrapper>
    <div className="container grid grid-filter-column">
      <div>
        <FilterSection  handleChange={handleChange}  query={query}  />
      </div>

      <section className="product-view--sort">
        <div className="sort-filter">
          <Sort result={result} />
        </div>
        <div className="main-product">
          <ProductList result={result} />
        </div>
      </section>
    </div>

  </Wrapper>;
};

const Wrapper = styled.section`
  .grid-filter-column {
    grid-template-columns: 0.2fr 1fr;
  }

  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    .grid-filter-column {
      grid-template-columns: 1fr;
    }
  }
`;

export default Products;
