
const cartReducer = (state, action) => {

    if (action.type === "ADD_TO_CART") {
        let { id, color, amount, product } = action.payload;
        let exitingProduct = state.cart.find(
            (curEl) => curEl.id === id + color)

        if (exitingProduct) {
            let updatedProduct = state.cart.map((curEl) => {
                if (curEl.id === id + color) {
                    let newAmount = curEl.amount + amount;
                    if (newAmount >= curEl.max) {
                        newAmount = curEl.max
                    }
                    return {
                        ...curEl,
                        amount: newAmount,
                    }
                } else {
                    return curEl;
                }
            })
            return {
                state,
                cart: updatedProduct,
            }
        } else {
            let cartProduct;
            cartProduct = {
                id: id + color,
                color,
                name: product.name,
                amount,
                image: product.image[0].url,
                price: product.price,
                max: product.stock
            }
            return {
                ...state,
                cart: [...state.cart, cartProduct]
            }
        }

    }

    if (action.type === "REMOVE_ITEM") {
        let updateCart = state.cart.filter((curEl) => {
            return curEl.id !== action.payload;
        })
        return {
            ...state,
            cart: updateCart
        }
    }

    if (action.type === "CLEAR_CART") {
        return {
            ...state,
            cart: []
        }
    }

    if (action.type === "SET_DECREASE") {
        let updatedProduct = state.cart.map((curEl) => {
            if (curEl.id === action.payload) {
                let deAmount = curEl.amount - 1;
                if (deAmount <= 0) {
                    deAmount = 1;
                }
                return {
                    ...curEl,
                    amount: deAmount
                }
            } else {
                return curEl;
            }
        })

        return { ...state, cart: updatedProduct }
    }
    if (action.type === "SET_INCREASE") {
        let updatedProduct = state.cart.map((curEl) => {
            if (curEl.id === action.payload) {
                let incAmount = curEl.amount + 1;
                if (incAmount >= curEl.max) {
                    incAmount = curEl.max;
                }
                return {
                    ...curEl,
                    amount: incAmount
                }
            } else {
                return curEl;
            }
        })

        return { ...state, cart: updatedProduct }
    }

    if (action.type === "CART_VALUE") {
    let total_item = state.cart.reduce((initialst, curEl) => {
        let { amount } = curEl;
        initialst = initialst + amount;
        return initialst;
    }, 0)
    return {
        ...state,
        total_item
    }
}

if(action.type==="TOTAL_PRICE"){
    let  total_price=state.cart.reduce((initipri,curEl)=>{
        let {price,amount}=curEl;
        initipri=initipri+(price*amount);
        return initipri
    },0)
    return {
        ...state,
        total_price,
    }
}
   
    return state
}

export default cartReducer;



// if (action.type === "CART_VALUE_TOTAL_PRICE") {
//     let { total_item, total_price } = state.cart.reduce((accum, curEl) => {
//         let { price, amount } = curEl;

//         accum.total_item += amount;
//         accum.total_price += price * amount;
        
//         return accum;

//     }, { total_item: 0, total_price:0 });
//     return {
//         ...state,
//         total_item,
//         total_price,
//     }
// }