import { useCartContext } from "../context/cartContext";
import { EmptyDiv, Wrapper } from "../styles/CartStyle";
import CartItem from "../componets/CartItem";
import { Button } from "../styles/Button";
import { NavLink } from "react-router-dom";
import FormatPrice from "../Helper/FormatPrice";

const Cart = () => {

  const { cart, clearCart, total_price, shipping_fee } = useCartContext();
  console.log(cart);
  console.log(total_price)

  if (cart.length === 0) {
    return <EmptyDiv>
      <h3>no item in cart</h3>
      <NavLink to='/products'>
        <Button>Continue Shopping</Button>
      </NavLink>
    </EmptyDiv>
  }


  return <Wrapper>
    <div className="container">
   <div className="cart_heading grid grid-five-column">
        <p >Item</p>
        <p className="cart-hide">Price</p>
        <p>Quantity</p>
        <p className="cart-hide">Subtotal</p>
        <p>Remove</p>
      </div>
      <hr />
      <div className="cart-item">
        {
          cart.map((data) => {
            return <CartItem key={data.id} {...data} />
          })
        }
      </div>
      <hr />

      <div className="cart-two-button">
        <NavLink to='/products'>
          <Button>Continue Shopping</Button>
        </NavLink>
        <Button className="btn-clear" onClick={clearCart} >clear cart</Button>
      </div>

      <div className="order-total--amount">
        <div className="order-total--subdata">
          <div>
            <p>Subtotal:</p>
            <p><FormatPrice price={total_price} /></p>
          </div>
          <div>
            <p>ShippingFee:</p>
            <p><FormatPrice price={shipping_fee} /></p>
          </div>
          <hr />
          <div>
            <p>Order total:</p>
            <p><FormatPrice price={shipping_fee + total_price} /></p>
          </div>
        </div>
      </div>
    </div>
  </Wrapper>;
};



export default Cart;
