import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from 'react-router-dom'
import 'react-icons/fi'
import 'react-icons/cg'
import { AppProvider } from "./context/Productcontext";
import { FilterContextProvider } from "./context/FilterContext";
import { CartProvider } from "./context/cartContext";
import { Auth0Provider } from "@auth0/auth0-react";
import { Toaster } from "react-hot-toast";
const root = ReactDOM.createRoot(document.getElementById("root"));

const domain=process.env.REACT_APP_AUTH_DOMAIN;
const clientId=process.env.REACT_APP_CLIENT_ID;

root.render(
  <Auth0Provider
  domain= {domain}
  clientId={clientId}
  authorizationParams={{
    redirect_uri: window.location.origin
  }}
>
<AppProvider>
<FilterContextProvider>
<CartProvider>
<BrowserRouter>
  <Toaster />
  <App />
  </BrowserRouter>
  </CartProvider>
  </FilterContextProvider>
  </AppProvider>
  </Auth0Provider>
   

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
