import React from 'react'
import FormatPrice from '../Helper/FormatPrice'
import {Button} from '../styles/Button'
import {NavLink} from 'react-router-dom'
import {Wrapper} from '../styles/ListViewStyle'

function ListView({ products }) {
    return (
        <Wrapper className="section">
            <div className="container grid">
                {
                    products.map((data) => {
                        const { id, name, image, price, description } = data
                        return <div key={data.id} className='card grid grid-two-column'>

                            <figure>
                                <img src={image} alt={name} />
                            </figure>
                            <div className='card-data'>
                                <h3>{name}</h3>
                                <p> <FormatPrice price={price} /> </p>
                                <p>{description.slice(0, 90)}</p>

                                <NavLink to={`/singleproduct/${id}`} className="btn-main">
                                    <Button className="btn">Read More</Button>
                                </NavLink>

                            </div>
                        </div>
                    })
                }
            </div>

        </Wrapper>
    )
}

export default ListView