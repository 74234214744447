import React, { useState } from 'react'
import { NavLink } from 'react-router-dom';
import { FiShoppingCart } from 'react-icons/fi';
import { CgMenu, CgClose } from 'react-icons/cg'
import { Wrapper } from '../styles/NavStyle';
import { useCartContext } from '../context/cartContext';
import { Button } from '../styles/Button';

function Nav({islogged,setIsLogged}) {


    const { total_item } = useCartContext();
    console.log(total_item)
    const [menuIcon, setMenuIcon] = useState();
    return (
        <Wrapper>
            <div className={menuIcon ? "navbar active" : "navbar"}>
                <ul className='navbar-lists'>
                    <li>
                        <NavLink to='/' className='navbar-link' onClick={() => setMenuIcon(false)} >
                            HOME
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/about' className='navbar-link' onClick={() => setMenuIcon(false)}>
                            ABOUT
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/products' className='navbar-link' onClick={() => setMenuIcon(false)} >
                            PRODUCTS
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/contact' className='navbar-link' onClick={() => setMenuIcon(false)} >
                            CONTACT
                        </NavLink>
                    </li>
                    {
                       islogged===true ?<Button onClick={()=>setIsLogged(false)}>LogOut</Button>: <NavLink to={'/login'}> <Button>Login</Button></NavLink>
                    }
                    <li>
                        <NavLink to='/cart' className='navbar-link cart-trolley--link' >
                            <FiShoppingCart className='cart-trolley' />
                            <span className='cart-total--item'>{total_item}</span>
                        </NavLink>
                    </li>
                </ul>
                <div className='mobile-navbar-btn'>
                    <CgMenu name="menu-outline" className='mobile-nav-icon' onClick={() => setMenuIcon(true)} />
                    <CgClose name='close-outline' className='mobile-nav-icon close-outline' onClick={() => setMenuIcon(false)} />
                </div>
            </div>
        </Wrapper>
    )
}

export default Nav