import React from 'react'
import { Wrapper } from '../styles/FeaturedProductsStyle'
import { useProductContext } from '../context/Productcontext'
import Product from './Product'


function FeatureProducts() {
 
    const {isLoading,featureProducts}=useProductContext()
    
    if(isLoading){
        return <div>.......Loading</div>
    }

  return (
    <Wrapper className='section'>
    <div className="container">
    <div className="intro-data">Check Now!</div>
    <div className="common-heading">Our Feature Services</div>
    <div className="grid grid-three-column">
    { featureProducts.map((data)=>{
            return <Product key={data.id} data={data}/>
        })}
    </div>
    </div>
    
    </Wrapper>
  )
}

export default FeatureProducts